import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
// import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "item-active",
  routes,
});

/**
 * Guards to handle accordingly authenticated views
 */
router.beforeEach(async (to, from, next) => {
  // If it is an authenticated user, try getting first the token from localStorage
  const accessToken = Vue.prototype.$getToken("access-token");

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    // Check if we have stored tokens in localstorage and try performing an authenticated call using it
    if (accessToken) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    // Check if we are autenticated to avoid load login view
    const existsRoute = to.name !== "PageNotFound";

    // If it is not a 404, show it
    if (existsRoute) {
      // Check if user is authenticated
      if (accessToken && !to.meta.forceDisplay) {
        // Redirect to main view
        next({ name: "DashboardView" });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
