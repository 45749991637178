import Vue from "vue";
import Vuex from "vuex";
import {
  europeanObjects,
  mexicanObjects,
} from "@/utils/spaceEstimationObjects";
import { readEstimation } from "@/api/endpoints/space-estimation";
import Decimal from "decimal.js";

Vue.use(Vuex);

const state = {
  budgetEstimation: {},
  spaceEstimated: 0,
  spaceEstimatedPerRoom: {},
  items: {
    ES: europeanObjects,
    FR: europeanObjects,
    PT: europeanObjects,
    IT: europeanObjects,
    MX: mexicanObjects,
  },
  defaultItems: {
    ES: structuredClone(europeanObjects),
    FR: structuredClone(europeanObjects),
    PT: structuredClone(europeanObjects),
    IT: structuredClone(europeanObjects),
    MX: structuredClone(mexicanObjects),
  },
  selectedItems: {},
  selectedItemsCounter: 0,
  nextPage: null,
  unsavedChanges: false,
  originalData: {},
};

// Create a deep copy from the state in order to restore it later
const stateCopy = JSON.stringify(state);

const mutations = {
  setBudgetEstimation(state, budgetEstimation) {
    state.budgetEstimation = budgetEstimation;
  },
  initSpaceEstimatedPerRoom(state, spaceEstimatedPerRoom) {
    state.spaceEstimatedPerRoom = spaceEstimatedPerRoom;
    state.spaceEstimated = new Decimal(
      Object.values(state.spaceEstimatedPerRoom).reduce(
        (a, b) => new Decimal(a).plus(new Decimal(b)),
        0
      )
    )
      .toDecimalPlaces(2)
      .toNumber();
  },
  initSelectedItems(state, selectedItems) {
    state.selectedItems = selectedItems;
  },
  setSelectedItemsCounter(state, selectedItemsCounter) {
    state.selectedItemsCounter = selectedItemsCounter;
  },
  setValue(state, { itemTab, itemEntry, itemKey, itemValue, value }) {
    if (state.items[itemTab] === undefined) {
      state.items[itemTab] = {};
    }

    if (state.items[itemTab][itemEntry] === undefined) {
      state.items[itemTab][itemEntry] = {};
    }

    if (state.items[itemTab][itemEntry][itemKey] === undefined) {
      state.items[itemTab][itemEntry][itemKey] = {};
    }

    // Needed to make the nested array reactive to changes
    // DO NOT THIS UNDER NORMAL CIRCUMSTANCES
    Vue.set(state.items[itemTab][itemEntry][itemKey], itemValue, value);
  },
  setSpaceEstimatedPerRoom(state, { itemKey, operatorOne, operatorTwo }) {
    if (state.spaceEstimatedPerRoom[itemKey] === undefined) {
      state.spaceEstimatedPerRoom[itemKey] = 0;
    }

    const decimalNumberOne = new Decimal(operatorOne).toDecimalPlaces(3);
    const decimalNumberTwo = new Decimal(operatorTwo).toDecimalPlaces(3);
    const decimalStoredValue = new Decimal(
      state.spaceEstimatedPerRoom[itemKey]
    ).toDecimalPlaces(3);
    const difference = decimalNumberOne.minus(decimalNumberTwo);

    state.spaceEstimatedPerRoom[itemKey] = new Decimal(
      Math.max(decimalStoredValue.plus(difference), 0)
    )
      .toDecimalPlaces(3)
      .toNumber();

    // Update global estimated space as well
    state.spaceEstimated = new Decimal(
      Object.values(state.spaceEstimatedPerRoom).reduce(
        (a, b) => new Decimal(a).plus(new Decimal(b)),
        0
      )
    )
      .toDecimalPlaces(2)
      .toNumber();
  },
  setSelectedItem(state, { item, deltaAmount }) {
    state.selectedItemsCounter = Math.max(
      state.selectedItemsCounter + deltaAmount,
      0
    );

    // Needed to make the nested array reactive to changes
    // DO NOT THIS UNDER NORMAL CIRCUMSTANCES
    Vue.set(state.selectedItems, item.id, item);

    if (item.amount <= 0) {
      delete state.selectedItems[item.id];
    }
  },
  addCustomItem(state, { itemTab, itemEntry, itemKey, item }) {
    // Ensure that itemTab exists in state
    if (state.items[itemTab] === undefined) {
      state.items[itemTab] = {};
    }

    if (state.items[itemTab][itemEntry] === undefined) {
      state.items[itemTab][itemEntry] = {};
    }

    // Finally, add item to items
    Vue.set(state.items[itemTab][itemEntry], itemKey, item);
  },
  setItems(state, items) {
    state.items = items;
  },
  clearItems(state, { itemTab, itemEntry }) {
    const items = state.items[itemTab][itemEntry];
    let totalItems = 0;
    for (const item in items) {
      totalItems += items[item].amount;

      items[item].amount = 0;
      items[item].total = 0;

      // Remove the selected items as well
      delete state.selectedItems[item];
    }

    // Substract dropped items to the total items counter
    state.selectedItemsCounter = Math.max(
      state.selectedItemsCounter - totalItems,
      0
    );

    // Reset the space estimated per room
    state.spaceEstimatedPerRoom[itemEntry] = new Decimal(0)
      .toDecimalPlaces(3)
      .toNumber();

    // Substract per room amount to total amount
    state.spaceEstimated = new Decimal(
      Object.values(state.spaceEstimatedPerRoom).reduce(
        (a, b) => new Decimal(a).plus(new Decimal(b)),
        0
      )
    )
      .toDecimalPlaces(2)
      .toNumber();

    // Finally set unsaved changes if we have already set items
    if (totalItems > 0) {
      state.unsavedChanges = true;
    }
  },
  resetAll(state) {
    const originalState = JSON.parse(stateCopy);

    state.budgetEstimation = originalState.budgetEstimation;
    state.spaceEstimated = originalState.spaceEstimated;
    state.spaceEstimatedPerRoom = originalState.spaceEstimatedPerRoom;
    state.items = originalState.items;
    state.selectedItems = originalState.selectedItems;
    state.selectedItemsCounter = originalState.selectedItemsCounter;
    state.nextPage = originalState.nextPage;
    state.unsavedChanges = originalState.unsavedChanges;
    state.originalData = originalState.originalData;
  },
  setNextPage(state, value) {
    state.nextPage = value;
  },
  copyState(state) {
    state.originalData = {
      spaceEstimated: state.spaceEstimated,
      spaceEstimatedPerRoom: state.spaceEstimatedPerRoom,
      items: state.items,
      selectedItems: state.selectedItems,
      selectedItemsCounter: state.selectedItemsCounter,
      budgetEstimation: state.budgetEstimation,
      unsavedChanges: state.unsavedChanges,
    };
  },
  restoreState(state) {
    state.spaceEstimated = state.originalData.spaceEstimated;
    state.spaceEstimatedPerRoom = state.originalData.spaceEstimatedPerRoom;
    state.items = state.originalData.items;
    state.selectedItems = state.originalData.selectedItems;
    state.selectedItemsCounter = state.originalData.selectedItemsCounter;
    state.budgetEstimation = state.originalData.budgetEstimation;
    state.unsavedChanges = state.originalData.unsavedChanges;
    state.originalData = {};
  },
  unsetState(state) {
    state.originalData = {};
  },
  setUnsavedChanges(state, value) {
    state.unsavedChanges = value;
  },
};

const actions = {
  async LOAD_BUDGET_ESTIMATION({ commit }, payload) {
    const response = await readEstimation(payload);
    commit("setBudgetEstimation", response.data);
    return response;
  },
  INIT_SPACE_ESTIMATED_PER_ROOM({ commit }, value) {
    commit("initSpaceEstimatedPerRoom", value);
  },
  INIT_SELECTED_ITEMS({ commit }, value) {
    commit("initSelectedItems", value);
  },
  SET_VALUE({ commit }, { itemTab, itemEntry, itemKey, itemValue, value }) {
    commit("setValue", { itemTab, itemEntry, itemKey, itemValue, value });
  },
  SET_SELECTED_ITEM_COUNTER({ commit }, value) {
    commit("setSelectedItemsCounter", value);
  },
  SET_SPACE_ESTIMATED_PER_ROOM(
    { commit },
    { itemKey, operatorOne, operatorTwo }
  ) {
    commit("setSpaceEstimatedPerRoom", {
      itemKey,
      operatorOne,
      operatorTwo,
    });
  },
  SET_SELECTED_ITEM({ commit }, { item, deltaAmount }) {
    commit("setSelectedItem", { item, deltaAmount });
  },
  ADD_CUSTOM_ITEM({ commit }, { itemTab, itemEntry, itemKey, item }) {
    commit("addCustomItem", { itemTab, itemEntry, itemKey, item });
  },
  SET_ITEMS({ commit }, items) {
    commit("setItems", items);
  },
  CLEAR_ITEMS({ commit }, { itemTab, itemEntry }) {
    commit("clearItems", { itemTab, itemEntry });
  },
  RESET_ITEMS({ commit }) {
    commit("resetAll");
  },
  SET_NEXT_PAGE({ commit }, nextPagePointer) {
    commit("setNextPage", nextPagePointer);
    return nextPagePointer;
  },
  UNSET_NEXT_PAGE({ commit }) {
    commit("setNextPage", null);
  },
  COPY_STATE({ commit }) {
    commit("copyState");
  },
  RESTORE_STATE({ commit }) {
    commit("restoreState");
  },
  UNSET_TEMPORAL_STATE({ commit }) {
    commit("unsetState");
  },
  SET_BUDGET_ESTIMATION({ commit }, budgetEstimation) {
    commit("setBudgetEstimation", budgetEstimation);
  },
  SET_UNSAVED_CHANGES({ commit }, value) {
    commit("setUnsavedChanges", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
