import { user, userHubspotProxy } from "@/api/endpoints/user";

export default {
  namespaced: true,
  state: {
    userData: {},
    userNextPage: null,
  },
  mutations: {
    setUserData: (state, data) => {
      state.userData = data;
    },
    setUserNextPage: (state, data) => {
      state.userNextPage = data;
    },
  },
  actions: {
    async LOAD_USER({ commit }, payload) {
      const response = await user(payload);
      commit("setUserData", response.data);
      return response;
    },
    async LOAD_USER_PROXY({ commit }, payload) {
      const response = await userHubspotProxy(payload);
      const data = {
        ...response.data,
        redirectFromHubspotProxy: true,
      };
      commit("setUserData", data);
      return response;
    },
    UNSET_REDIRECTION_FLAG({ commit, state }) {
      if (state.userData.redirectFromHubspotProxy) {
        const data = {
          ...state.userData,
          redirectFromHubspotProxy: false,
        };
        commit("setUserData", data);
      }
    },
    SET_NEXT_PAGE({ commit }, nextPagePointer) {
      commit("setUserNextPage", nextPagePointer);
      return nextPagePointer;
    },
    UNSET_NEXT_PAGE({ commit }) {
      commit("setUserNextPage", null);
    },
  },
};
