import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
  mainNavbarCollapsed: false,
  globalNavbarCollapsed: false,
  homeCalendarNavbarCollapsed: false,
};

const mutations = {
  setMainNavbarCollapsed(state, mainNavbarCollapsed) {
    state.mainNavbarCollapsed = mainNavbarCollapsed;
  },
  setGlobalNavbarCollapsed(state, globalNavbarCollapsed) {
    state.globalNavbarCollapsed = globalNavbarCollapsed;
  },
  setHomeCalendarNavbarCollapsed(state, homeCalendarNavbarCollapsed) {
    state.homeCalendarNavbarCollapsed = homeCalendarNavbarCollapsed;
  },
};

const actions = {
  SET_MAIN_NAVBAR_COLLAPSED({ commit }, value) {
    commit("setMainNavbarCollapsed", value);
  },
  SET_GLOBAL_NAVBAR_COLLAPSED({ commit }, value) {
    commit("setGlobalNavbarCollapsed", value);
  },
  SET_HOME_CALENDAR_NAVBAR_COLLAPSED({ commit }, value) {
    commit("setHomeCalendarNavbarCollapsed", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
