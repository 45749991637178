import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuelidate from "vuelidate";
import VueI18n from "vue-i18n";
import esLocale from "@/i18n/es.json";
import enLocale from "@/i18n/en.json";
import frLocale from "@/i18n/fr.json";
import ptLocale from "@/i18n/pt.json";
import itLocale from "@/i18n/it.json";
import mxLocale from "@/i18n/mx.json";
import VueApi from "@/api";
import VueAuthentication from "@/helpers/tokens";
import VueToast from "@/components/uiGeneral/uiToast/index";
import store from "@/store";
import VueFormat from "@/helpers/format";
import VueEmpty from "@/helpers/empty";
import VueCheckI18nKey from "@/helpers/existsTranslation";
import VueEquals from "@/helpers/swallowEquality";
import CountryFlag from "vue-country-flag";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VCalendar from "v-calendar";

import "./index.css";

// Import JS extensions
import "@/utils/extensions";

// Core Vue setup
Vue.config.productionTip = process.env.VUE_APP_ENVIRONMENT === "production";
Vue.config.performance = process.env.VUE_APP_ENVIRONMENT !== "production";
Vue.config.devtools = process.env.VUE_APP_ENVIRONMENT !== "production";

// Global event bus
window.eventBus = new Vue();

Vue.use(Vuelidate);

// api configuration
Vue.use(VueApi);

// token helper methods
Vue.use(VueAuthentication);

// Toast utility
Vue.use(VueToast);

// Use format polyfill
Vue.use(VueFormat);

// Helper plugin to check emptyness
Vue.use(VueEmpty);

// Swallow equality
Vue.use(VueEquals);

// I18n helper method to ensure the existance of keys
Vue.use(VueCheckI18nKey);

// Flags
Vue.component("country-flag", CountryFlag);

// Calendar
Vue.use(VCalendar);

// i18n config
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  silentFallbackWarn: true,
  messages: {
    es: esLocale,
    en: enLocale,
    fr: frLocale,
    pt: ptLocale,
    it: itLocale,
    mx: mxLocale,
  },
});

// Sentry configuration
if (process.env.VUE_APP_ENVIRONMENT !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://4da69d2ea31d4ead83f8bf7c4796df4f@o992869.ingest.sentry.io/6433977",
    release: `box2box-backoffice-webapp@${process.env.VUE_APP_VERSION}`,
    environment: process.env.VUE_APP_ENVIRONMENT,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.7,
    ignoreErrors: ["Network Error"],
    logErrors: true,
    trackComponents: true,
  });
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
