import Vue from "vue";
import Vuex from "vuex";
import { readEstimation } from "@/api/endpoints/space-estimation";

Vue.use(Vuex);

const SPACE_MULTIPLIER = 1.5;

const getDefaultState = () => {
  return {
    objects_amount: 0,
    total_space: 0,
    objects: [
      {
        id: "bed_160",
        amount: 0,
        width: 200,
        height: 150,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "bed_135",
        amount: 0,
        width: 200,
        height: 135,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "bed_80",
        amount: 0,
        width: 200,
        height: 90,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "trundle_bed",
        amount: 0,
        width: 200,
        height: 90,
        depth: 80,
        total: 0,
        room: "bedroom",
      },
      {
        id: "headboard",
        amount: 0,
        width: 100,
        height: 300,
        depth: 5,
        total: 0,
        room: "bedroom",
      },
      {
        id: "normal_desk",
        amount: 0,
        width: 75,
        height: 100,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "big_desk",
        amount: 0,
        width: 80,
        height: 140,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "night_table",
        amount: 0,
        width: 60,
        height: 50,
        depth: 40,
        total: 0,
        room: "bedroom",
      },
      {
        id: "wardrobe_2",
        amount: 0,
        width: 200,
        height: 100,
        depth: 60,
        total: 0,
        room: "bedroom",
      },
      {
        id: "wardrobe_3",
        amount: 0,
        width: 210,
        height: 150,
        depth: 60,
        total: 0,
        room: "bedroom",
      },
      {
        id: "drawer",
        amount: 0,
        width: 100,
        height: 60,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "commode",
        amount: 0,
        width: 100,
        height: 80,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "office_desk",
        amount: 0,
        width: 75,
        height: 150,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "sinfonier",
        amount: 0,
        width: 200,
        height: 80,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "bathroom_hanging_drawer",
        amount: 0,
        width: 50,
        height: 50,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "shoe_rack",
        amount: 0,
        width: 80,
        height: 80,
        depth: 30,
        total: 0,
        room: "bedroom",
      },
      {
        id: "shelf",
        amount: 0,
        width: 100,
        height: 80,
        depth: 40,
        total: 0,
        room: "bedroom",
      },
      {
        id: "buffet_sideboard",
        amount: 0,
        width: 100,
        height: 70,
        depth: 40,
        total: 0,
        room: "bedroom",
      },

      {
        id: "sofa",
        amount: 0,
        width: 240,
        height: 100,
        depth: 80,
        total: 0,
        room: "livingroom",
      },
      {
        id: "sofa_l",
        amount: 0,
        width: 180,
        height: 120,
        depth: 160,
        total: 0,
        room: "livingroom",
      },
      {
        id: "sleeper_sofa",
        amount: 0,
        width: 200,
        height: 100,
        depth: 80,
        total: 0,
        room: "livingroom",
      },
      {
        id: "dining_table",
        amount: 0,
        width: 80,
        height: 150,
        depth: 50,
        total: 0,
        room: "livingroom",
      },
      {
        id: "desmounted_dining_table",
        amount: 0,
        width: 150,
        height: 100,
        depth: 20,
        total: 0,
        room: "livingroom",
      },
      {
        id: "auxiliary_table",
        amount: 0,
        width: 40,
        height: 100,
        depth: 40,
        total: 0,
        room: "livingroom",
      },
      {
        id: "livingroom_modules",
        amount: 0,
        width: 200,
        height: 100,
        depth: 50,
        total: 0,
        room: "livingroom",
      },
      {
        id: "office_chair",
        amount: 0,
        width: 80,
        height: 60,
        depth: 60,
        total: 0,
        room: "livingroom",
      },
      {
        id: "chairs",
        amount: 0,
        width: 80,
        height: 60,
        depth: 40,
        total: 0,
        room: "livingroom",
      },
      {
        id: "cupboard",
        amount: 0,
        width: 100,
        height: 120,
        depth: 40,
        total: 0,
        room: "livingroom",
      },
      {
        id: "tv_furniture",
        amount: 0,
        width: 70,
        height: 150,
        depth: 40,
        total: 0,
        room: "livingroom",
      },
      {
        id: "showcase",
        amount: 0,
        width: 200,
        height: 100,
        depth: 50,
        total: 0,
        room: "livingroom",
      },
      {
        id: "floor_lamp",
        amount: 0,
        width: 100,
        height: 50,
        depth: 30,
        total: 0,
        room: "livingroom",
      },
      {
        id: "armchair",
        amount: 0,
        width: 80,
        height: 70,
        depth: 70,
        total: 0,
        room: "livingroom",
      },
      {
        id: "bookshelf",
        amount: 0,
        width: 200,
        height: 100,
        depth: 50,
        total: 0,
        room: "livingroom",
      },
      {
        id: "glass_table",
        amount: 0,
        width: 100,
        height: 100,
        depth: 40,
        total: 0,
        room: "livingroom",
      },
      {
        id: "ceiling_lamp",
        amount: 0,
        width: 30,
        height: 50,
        depth: 30,
        total: 0,
        room: "livingroom",
      },
      {
        id: "entrance_furniture",
        amount: 0,
        width: 100,
        height: 120,
        depth: 40,
        total: 0,
        room: "livingroom",
      },
      {
        id: "appliances",
        amount: 0,
        width: 80,
        height: 60,
        depth: 60,
        total: 0,
        room: "kitchen",
      },
      {
        id: "fridge",
        amount: 0,
        width: 200,
        height: 70,
        depth: 60,
        total: 0,
        room: "kitchen",
      },
      {
        id: "american_fridge",
        amount: 0,
        width: 200,
        height: 120,
        depth: 80,
        total: 0,
        room: "kitchen",
      },
      {
        id: "stacking_chairs",
        amount: 0,
        width: 80,
        height: 50,
        depth: 5,
        total: 0,
        room: "kitchen",
      },
      {
        id: "no_stacking_chairs",
        amount: 0,
        width: 80,
        height: 50,
        depth: 50,
        total: 0,
        room: "kitchen",
      },
      {
        id: "microwave",
        amount: 0,
        width: 30,
        height: 40,
        depth: 40,
        total: 0,
        room: "kitchen",
      },
      {
        id: "kitchen_table",
        amount: 0,
        width: 75,
        height: 110,
        depth: 50,
        total: 0,
        room: "kitchen",
      },
      {
        id: "terrace_table",
        amount: 0,
        width: 75,
        height: 100,
        depth: 50,
        total: 0,
        room: "kitchen",
      },
      {
        id: "terrace_armchair",
        amount: 0,
        width: 70,
        height: 70,
        depth: 70,
        total: 0,
        room: "kitchen",
      },
      {
        id: "kitchen_drawer",
        amount: 0,
        width: 100,
        height: 40,
        depth: 40,
        total: 0,
        room: "kitchen",
      },
      {
        id: "entrance_glass_table",
        amount: 0,
        width: 100,
        height: 130,
        depth: 40,
        total: 0,
        room: "kitchen",
      },
      {
        id: "standing_coat_rack",
        amount: 0,
        width: 100,
        height: 20,
        depth: 20,
        total: 0,
        room: "kitchen",
      },
      {
        id: "pantry_cabinet",
        amount: 0,
        width: 180,
        height: 100,
        depth: 40,
        total: 0,
        room: "kitchen",
      },
      {
        id: "l_box",
        amount: 0,
        width: 70,
        height: 50,
        depth: 50,
        total: 0,
        room: "other",
      },
      {
        id: "m_box",
        amount: 0,
        width: 50,
        height: 35,
        depth: 35,
        space_multiplier: 1.9,
        total: 0,
        room: "other",
      },
      {
        id: "s_box",
        amount: 0,
        width: 35,
        height: 30,
        depth: 20,
        space_multiplier: 2.5,
        total: 0,
        room: "other",
      },
      {
        id: "w_box",
        amount: 0,
        width: 100,
        height: 60,
        depth: 50,
        space_multiplier: 1.8,
        total: 0,
        room: "other",
      },
      {
        id: "suitcase",
        amount: 0,
        width: 60,
        height: 70,
        depth: 30,
        total: 0,
        room: "other",
      },
      {
        id: "mirror",
        amount: 0,
        width: 100,
        height: 70,
        depth: 5,
        total: 0,
        room: "other",
      },
      {
        id: "tv",
        amount: 0,
        width: 70,
        height: 110,
        depth: 20,
        total: 0,
        room: "other",
      },
      {
        id: "bike",
        amount: 0,
        width: 50,
        height: 120,
        depth: 50,
        total: 0,
        room: "other",
      },
      {
        id: "shopping_cart",
        amount: 0,
        width: 40,
        height: 40,
        depth: 40,
        total: 0,
        room: "other",
      },
      {
        id: "stool",
        amount: 0,
        width: 100,
        height: 50,
        depth: 60,
        total: 0,
        room: "other",
      },
      {
        id: "fan",
        amount: 0,
        width: 100,
        height: 50,
        depth: 30,
        total: 0,
        room: "other",
      },
      {
        id: "chest",
        amount: 0,
        width: 50,
        height: 110,
        depth: 50,
        total: 0,
        room: "other",
      },
      {
        id: "bureau",
        amount: 0,
        width: 100,
        height: 100,
        depth: 40,
        total: 0,
        room: "other",
      },
    ],
    backupData: null,
  };
};

const state = getDefaultState();

const mutations = {
  updateObjectAmount: (state, payload) => {
    state["objects"].filter((object) => object.id === payload.id)[0]["amount"] =
      parseInt(payload.amount) || 0;
  },
  updateObjectSpace: (state, id) => {
    const object = state["objects"].filter((object) => object.id === id)[0];
    const space =
      (parseFloat(object["width"]) || 0) *
      (parseFloat(object["height"]) || 0) *
      (parseFloat(object["depth"]) || 0) *
      (parseFloat(object["amount"]) || 0) *
      (parseFloat(object["space_multiplier"]) || SPACE_MULTIPLIER);
    state["objects"].filter((object) => object.id === id)[0]["total"] =
      Math.max(parseFloat(space), 0);
  },
  removeObjectsAmount: (state, id) => {
    state.objects_amount -= Math.max(
      parseInt(
        state["objects"].filter((object) => object.id === id)[0]["amount"]
      ) || 0,
      0
    );
  },
  addObjectsAmount: (state, amount) => {
    state.objects_amount += Math.max(parseInt(amount) || 0, 0);
  },
  removeSpaceAmount: (state, id) => {
    state.total_space -= Math.max(
      parseFloat(
        state["objects"].filter((object) => object.id === id)[0]["total"]
      ) || 0,
      0
    );
  },
  addSpaceAmount: (state, payload) => {
    state.total_space += Math.max(
      parseFloat(
        state["objects"].filter((object) => object.id === payload.id)[0][
          "total"
        ]
      ) || 0,
      0
    );
  },
  updateSize: (state, payload) => {
    state["objects"].filter((object) => object.id === payload.id)[0][
      payload.size
    ] = payload.value;
  },
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
  loadState: (state, payload) => {
    state["objects"] = payload.rawData["objects"];
    state["total_space"] = payload.rawData["total_space"];
    state["objects_amount"] = payload.rawData["objects_amount"];
  },
  loadReadState: (state, payload) => {
    state["objects"] = payload.data.raw_data["objects"];
    state["total_space"] = payload.data.raw_data["total_space"];
    state["objects_amount"] = payload.data.raw_data["objects_amount"];
  },
  backupState: (state) => {
    state["backupData"] = {
      objects: state["objects"],
      total_space: state["total_space"],
      objects_amount: state["objects_amount"],
    };
  },
  restoreState: (state) => {
    if (state["backupData"]) {
      state["objects"] = state["backupData"]["objects"];
      state["total_space"] = state["backupData"]["total_space"];
      state["objects_amount"] = state["backupData"]["objects_amount"];
      delete state["backupData"];
    }
  },
  deleteBackup: (state) => {
    delete state["backupData"];
  },
  removeObject: (state, payload) => {
    let index = state["objects"].findIndex(
      (object) => object.id === payload.id
    );
    if (index >= 0) {
      state["objects"].splice(index, 1);
    }
  },
  addObject: (state, payload) => {
    state["objects"].push({
      id: payload.id,
      amount: 0,
      width: 0,
      height: 0,
      depth: 0,
      total: 0,
      room: payload.room,
    });
  },
};

const actions = {
  addObject({ commit }, payload) {
    commit("addObject", payload);
  },
  removeObject({ commit }, payload) {
    commit("removeObjectsAmount", payload.id);
    commit("updateObjectAmount", payload);
    commit("addObjectsAmount", payload.amount);
    commit("removeSpaceAmount", payload.id);
    commit("updateObjectSpace", payload.id);
    commit("addSpaceAmount", payload);
    commit("removeObject", payload);
  },
  updateAmount({ commit }, payload) {
    commit("removeObjectsAmount", payload.id);
    commit("updateObjectAmount", payload);
    commit("addObjectsAmount", payload.amount);
  },
  updateSpace({ commit }, payload) {
    commit("removeSpaceAmount", payload.id);
    commit("updateObjectSpace", payload.id);
    commit("addSpaceAmount", payload);
  },
  updateSize({ commit }, payload) {
    commit("updateSize", payload);
    commit("removeSpaceAmount", payload.id);
    commit("updateObjectSpace", payload.id);
    commit("addSpaceAmount", payload);
  },
  resetView({ commit }) {
    commit("resetState");
  },
  loadData({ commit }, payload) {
    commit("loadState", payload);
  },
  saveState({ commit }) {
    commit("backupState");
  },
  keepEditing({ commit }) {
    commit("restoreState");
  },
  restoreVersion({ commit }) {
    commit("deleteBackup");
  },
  async READ_ESTIMATION({ commit }, payload) {
    const response = await readEstimation(payload);
    commit("loadReadState", response);
    return response;
  },
};

const filterGetterRoom = (state, key) => {
  return state["objects"].filter((object) => object.room === key);
};

const getters = {
  bedroomObjects: (state) => filterGetterRoom(state, "bedroom"),
  livingroomObjects: (state) => filterGetterRoom(state, "livingroom"),
  kitchenObjects: (state) => filterGetterRoom(state, "kitchen"),
  otherObjects: (state) => filterGetterRoom(state, "other"),
  selectedObjectList: (state) =>
    state["objects"].filter((object) => object.amount > 0),
  allObjects: (state) => state,
  objectsAmount: (state) => state.objects_amount,
  totalSpace: (state) => parseFloat((state.total_space / 2 / 1e6).toFixed(2)),
  hasNegativeValues: (state) =>
    state["objects"].some(
      (object) =>
        object.amount < 0 ||
        object.width < 0 ||
        object.height < 0 ||
        object.depth < 0
    ),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
