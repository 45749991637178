import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    contractId: null,
    delivery: {
      id: null,
      statusPublish: false,
      dateDelivery: null,
      dateWarehouseSent: null,
      deliveryObjects: [],
      deliveryType: null,
      access: null,
      floor: null,
      furniture_assembly: false,
    },
    inventoryNextPage: null,
  },
  mutations: {
    setDeliveryType: (state, data) => {
      state.delivery.deliveryType = data;
    },
    setContractId: (state, contractid) => {
      state.contractId = contractid;
    },
    setDeliveryId: (state, deliveryid) => {
      state.delivery.id = deliveryid;
    },
    setStatusPublish: (state, statusPublish) => {
      state.delivery.statusPublish = statusPublish;
    },
    setDateDelivery: (state, data) => {
      state.delivery.dateDelivery = data;
    },
    setDeliveryObjects: (state, data) => {
      state.delivery.deliveryObjects = data;
    },
    setDateWarehouseSent: (state, data) => {
      state.delivery.dateWarehouseSent = data;
    },
    setInventoryNextPage: (state, data) => {
      state.inventoryNextPage = data;
    },
    setAccess: (state, data) => {
      state.delivery.access = data;
    },
    setFurnitureAssembly: (state, data) => {
      state.delivery.furniture_assembly = data;
    },
    setFloor: (state, data) => {
      state.delivery.floor = data;
    },
    resetDelivery: (state) => {
      state.delivery = {
        id: null,
        statusPublish: false,
        dateDelivery: null,
        dateWarehouseSent: null,
        deliveryObjects: [],
        deliveryType: null,
        access: null,
        floor: null,
        furniture_assembly: false,
      };
    },
  },
  actions: {
    SET_NEXT_PAGE({ commit }, nextPagePointer) {
      commit("setInventoryNextPage", nextPagePointer);
      return nextPagePointer;
    },
    UNSET_DELIVERY({ commit }) {
      commit("resetDelivery");
    },
  },
  getters: {
    contractId: (state) => state.contractId,
    deliveryId: (state) => state.delivery.id,
    statusPublish: (state) => state.delivery.statusPublish,
    getDateDelivery: (state) => state.delivery.dateDelivery,
    getSelectedObject: (state) => state.delivery.deliveryObjects,
    getDateWarehouseSent: (state) => state.delivery.dateWarehouseSent,
    getDeliveryType: (state) => state.delivery.deliveryType,
    getAccess: (state) => state.delivery.access,
    getFurnitureAssembly: (state) => state.delivery.furniture_assembly,
    getFloor: (state) => state.delivery.floor,
  },
};
