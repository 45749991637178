import Vue from "vue";
import { login, refreshToken, loggedUser } from "@/api/endpoints/auth";

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUserData: (state, user) => {
      state.user = user;
    },
    loginSuccess: (state, user) => {
      state.user = user;
    },
    logout: (state) => {
      state.user = null;
    },
  },
  actions: {
    async LOGIN({ commit }, payload) {
      const response = await login(payload);

      // Store tokens
      Vue.prototype.$saveAccessToken(response.data.access_token);
      Vue.prototype.$saveRefreshToken(response.data.refresh_token);

      commit("loginSuccess", response.data.user);
      return response;
    },
    async LOAD_USER({ commit }, payload) {
      const response = await loggedUser(payload);
      commit("setUserData", response.data);
      return response;
    },
    async REFRESH_TOKEN(_, payload) {
      const response = await refreshToken(payload);

      // Update token
      Vue.prototype.$saveAccessToken(response.data.access);
      return response;
    },
    async LOGOUT({ commit }) {
      Vue.prototype.$removeTokens();

      commit("logout");

      // Reload the session to drop store data
      window.location.reload();
    },
  },
};
