import { listCalendars } from "@/api/endpoints/calendar";

export default {
  namespaced: true,
  state: {
    calendars: [],
    calendarNextPage: null,
  },
  mutations: {
    setCalendarList: (state, { list }) => {
      state.calendars = list;
    },
    updateCalendar(state, { calendarId, updatedCalendar }) {
      const calendarIndex = state.calendars.findIndex(
        (calendar) => calendar.id === calendarId
      );

      if (calendarIndex > -1) {
        state.calendars[calendarIndex] = updatedCalendar;
      }
    },
    setNextPage(state, value) {
      state.calendarNextPage = value;
    },
  },
  actions: {
    async LOAD_CALENDARS({ commit }, params) {
      const response = await listCalendars(params);
      commit("setCalendarList", { list: response.data.results });
      return response;
    },
    SET_CALENDARS({ commit }, value) {
      commit("setCalendarList", { list: value });
    },
    UPDATE_CALENDAR({ commit }, { calendar, updatedCalendar }) {
      commit("updateCalendar", { calendar, updatedCalendar });
    },
    SET_NEXT_PAGE({ commit }, value) {
      commit("setNextPage", value);
    },
  },
};
