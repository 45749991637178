export default [
  {
    path: "/",
    component: () => import("@/views/private/MainView.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: () => import("@/views/private/WrapperView.vue"),
        children: [
          {
            path: "",
            name: "DashboardView",
            component: () => import("@/views/private/CalendarView.vue"),
          },
          {
            path: "/home/",
            redirect: { name: "ReminderView" },
            component: () => import("@/views/private/HomeView.vue"),
            children: [
              {
                path: "reminder/",
                name: "ReminderView",
                component: () =>
                  import("@/views/private/homeViews/ReminderView.vue"),
              },
              {
                path: "calendar/",
                component: () =>
                  import("@/views/private/homeViews/HomeCalendarView.vue"),
                children: [
                  {
                    path: "",
                    name: "ServicesView",
                    component: () =>
                      import(
                        "@/views/private/calendarConfigurationViews/ServicesView.vue"
                      ),
                  },
                  {
                    path: "routes/",
                    name: "RoutesView",
                    component: () =>
                      import(
                        "@/views/private/calendarConfigurationViews/RoutesView.vue"
                      ),
                  },
                  {
                    path: "tasks/",
                    name: "TasksView",
                    component: () =>
                      import(
                        "@/views/private/calendarConfigurationViews/TasksView.vue"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/warehouse",
        name: "Warehouse",
        component: () => import("@/views/private/WarehouseView.vue"),
        meta: {
          subheader: true,
        },
      },
      {
        path: "/users/:user/",
        component: () => import("@/views/private/WrapperView.vue"),
        children: [
          {
            path: "",
            name: "UserView",
            component: () => import("@/views/private/UserView.vue"),
          },
        ],
      },
      {
        path: "/hubspot/",
        component: () => import("@/views/private/WrapperView.vue"),
        redirect: { name: "PageNotFound" },
        children: [
          {
            path: "u/:user/",
            name: "HubspotUserView",
            component: () => import("@/views/private/HubspotRedirectView.vue"),
            meta: {
              proxy: "user",
            },
          },
          {
            path: "c/:contract/",
            name: "HubspotContractView",
            component: () => import("@/views/private/HubspotRedirectView.vue"),
            meta: {
              proxy: "contract",
            },
          },
        ],
      },
      {
        path: "/contracts/:contract/",
        component: () => import("@/views/private/WrapperView.vue"),
        children: [
          {
            path: "",
            name: "ContractView",
            component: () => import("@/views/private/ContractView.vue"),
          },
          {
            path: "space-budget/",
            name: "SpaceEstimationEmptyContractView",
            component: () =>
              import("@/views/private/SpaceEstimationViewRefactor.vue"),
            meta: {
              subheader: true,
              history: true,
              proxy: "spaceEstimation",
            },
          },
          {
            path: "space-budget/:estimation/",
            name: "SpaceEstimationContractView",
            component: () =>
              import("@/views/private/SpaceEstimationViewRefactor.vue"),
            meta: {
              subheader: true,
              history: true,
              proxy: "spaceEstimation",
            },
          },
          {
            path: "inventory/",
            name: "InventoryContractView",
            component: () => import("@/views/private/InventoryView.vue"),
            meta: {
              subheader: true,
            },
          },
          {
            path: "delivery/",
            name: "DeliveryRequestContractView",
            component: () => import("@/views/private/DeliveryRequest.vue"),
            meta: {
              subheader: true,
              history: true,
              proxy: "delivery",
            },
          },
          {
            path: "delivery/:delivery/",
            name: "DeliveryContractView",
            component: () => import("@/views/private/DeliveryRequest.vue"),
            meta: {
              subheader: true,
              history: true,
              proxy: "delivery",
            },
          },
        ],
      },
      {
        path: "inventory",
        name: "Inventory",
        component: () => import("@/views/private/InventoryView.vue"),
        meta: {
          subheader: true,
          history: true,
        },
      },
      {
        path: "list",
        name: "list",
        component: () => import("@/components/uiListObject/uiList.vue"),
        meta: {
          subheader: true,
        },
      },
      {
        path: "space-budget",
        name: "SpaceEstimationView",
        component: () =>
          import("@/views/private/SpaceEstimationViewRefactor.vue"),
        meta: {
          history: true,
          subheader: true,
          proxy: "spaceEstimation",
        },
        children: [
          {
            path: "/space-budget/:estimation/",
            name: "SpaceEstimationConcrete",
            component: () =>
              import("@/views/private/SpaceEstimationViewRefactor.vue"),
            meta: {
              history: true,
              subheader: true,
              basePath: "/space-budget",
              proxy: "spaceEstimation",
            },
          },
        ],
      },
      {
        path: "settings",
        component: () => import("@/views/private/settings/SettingsView.vue"),
        children: [
          {
            path: "/settings/global/",
            name: "SettingsView",
            redirect: { name: "GlobalSettingsChargebeeView" },
            component: () =>
              import("@/views/private/settings/GlobalSettingsView.vue"),
            children: [
              {
                path: "/settings/global/chargebee/",
                name: "GlobalSettingsChargebeeView",
                component: () =>
                  import(
                    "@/views/private/settings/global/ChargebeeSettingsView.vue"
                  ),
              },
              {
                path: "/settings/global/riders/",
                name: "RidersView",
                component: () =>
                  import(
                    "@/views/private/calendarConfigurationViews/RidersView.vue"
                  ),
              },

              {
                path: "/settings/global/warehouses/",
                name: "WarehousesView",
                component: () =>
                  import(
                    "@/views/private/calendarConfigurationViews/WarehousesView.vue"
                  ),
              },
            ],
          },
          {
            path: "/settings/:user/",
            name: "UserSettingsView",
            component: () =>
              import("@/views/private/settings/UserSettingsView.vue"),
          },
        ],
      },
      // {
      //   path: "/calendar",
      //   component: () => import("@/views/private/WrapperView.vue"),
      //   children: [
      //     {
      //       path: "",
      //       name: "CalendarView",
      //       component: () => import("@/views/private/CalendarView.vue"),
      //     },
      //     {
      //       path: "/calendar/configuration/",
      //       component: () =>
      //         import("@/views/private/CalendarConfigurationView.vue"),
      //       children: [
      //         {
      //           path: "",
      //           name: "RidersView",
      //           component: () =>
      //             import(
      //               "@/views/private/calendarConfigurationViews/RidersView.vue"
      //             ),
      //         },
      //         {
      //           path: "/calendar/configuration/warehouses/",
      //           name: "WarehousesView",
      //           component: () =>
      //             import(
      //               "@/views/private/calendarConfigurationViews/WarehousesView.vue"
      //             ),
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/LoginView.vue"),
  },
  {
    path: "/activate/:uid/:token/",
    name: "ActivateAccountView",
    component: () => import("@/views/public/ActivateAccountView.vue"),
    meta: {
      requiresAuth: false,
      forceDisplay: true,
    },
  },
  {
    path: "/recover-password/:uid/:token/",
    name: "RecoveryPasswordView",
    component: () => import("@/views/public/RecoveryPasswordView.vue"),
    meta: {
      requiresAuth: false,
      forceDisplay: true,
    },
  },
  {
    path: "/app/",
    component: () => import("@/views/private/WrapperView.vue"),
    children: [
      {
        path: "",
        name: "RiderBaseView",
        component: () =>
          import("@/views/public/riderAccountViews/RiderBaseView.vue"),
        children: [
          {
            path: "activate/:uid/:token/",
            name: "RiderActivateAccountView",
            component: () =>
              import(
                "@/views/public/riderAccountViews/RiderActivateAccountView.vue"
              ),
            meta: {
              requiresAuth: false,
              forceDisplay: true,
            },
          },
          {
            path: "account/activated/",
            name: "RiderAccountActivatedView",
            component: () =>
              import(
                "@/views/public/riderAccountViews/RiderAccountActivatedView.vue"
              ),
            meta: {
              requiresAuth: false,
              forceDisplay: true,
            },
          },
          {
            path: "download/",
            name: "RiderAppDownloadView",
            component: () =>
              import(
                "@/views/public/riderAccountViews/RiderAppDownloadView.vue"
              ),
            meta: {
              requiresAuth: false,
              forceDisplay: true,
            },
          },
          {
            path: "*",
            name: "RiderAccountEmptyView",
            component: () =>
              import(
                "@/views/public/riderAccountViews/RiderAccountEmptyView.vue"
              ),
            meta: {
              requiresAuth: false,
              forceDisplay: true,
            },
          },
        ],
        meta: {
          requiresAuth: false,
          forceDisplay: true,
        },
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFoundView.vue"),
  },
];
