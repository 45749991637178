import {
  contracts,
  contract,
  contractHubspotProxy,
} from "@/api/endpoints/contract";

export default {
  namespaced: true,
  state: {
    contractsList: {},
    contractData: {},
    contractNextPage: null,
    contractSlotData: {},
  },
  mutations: {
    setContractsList: (state, { list }) => {
      state.contractsList = list;
    },
    setContractData: (state, data) => {
      state.contractData = data;
    },
    setContractNextPage: (state, data) => {
      state.contractNextPage = data;
    },
    setContractSlotData: (state, data) => {
      state.contractSlotData = data;
    },
  },
  actions: {
    async LOAD_CONTRACTS({ commit }, payload) {
      const response = await contracts(payload);
      commit("setContractsList", { list: response.data });
      return response;
    },
    async LOAD_CONTRACT({ commit }, payload) {
      const response = await contract(payload);
      commit("setContractData", response.data);
      return response;
    },
    async LOAD_CONTRACT_PROXY({ commit }, payload) {
      const response = await contractHubspotProxy(payload);
      const data = {
        ...response.data,
        redirectFromHubspotProxy: true,
      };
      commit("setContractData", data);
      return response;
    },
    /* Internal action to handle slot reservation in google calendar */
    SET_CONTRACT_SLOT_DATA({ commit }, data) {
      commit("setContractSlotData", data);
    },
    UNSET_CONTRACT_SLOT_DATA({ commit }) {
      commit("setContractSlotData", null);
    },
    UNSET_REDIRECTION_FLAG({ commit, state }) {
      if (state.contractData.redirectFromHubspotProxy) {
        const data = {
          ...state.contractData,
          redirectFromHubspotProxy: false,
        };
        commit("setContractData", data);
      }
    },
    SET_NEXT_PAGE({ commit }, nextPagePointer) {
      commit("setContractNextPage", nextPagePointer);
      return nextPagePointer;
    },
    UNSET_NEXT_PAGE({ commit }) {
      commit("setContractNextPage", null);
    },
    UNSET_CONTRACT({ commit }) {
      commit("setContractData", {});
    },
  },
};
