import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import users from "./modules/users";
import contracts from "./modules/contracts";
import inventory from "./modules/inventory";
import spaceEstimation from "./modules/spaceEstimation";
import spaceEstimationRefactor from "./modules/spaceEstimationRefactor";
import site from "./modules/site";
import locale from "./modules/locale";
import warehouses from "./modules/warehouses";
import calendars from "./modules/calendars";
import app from "./modules/app";
import settings from "./modules/settings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    spaceEstimation,
    inventory,
    users,
    contracts,
    spaceEstimationRefactor,
    site,
    locale,
    warehouses,
    calendars,
    app,
    settings,
  },
});
