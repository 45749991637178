import { warehouseCities } from "@/api/endpoints/warehouses";

export default {
  namespaced: true,
  state: {
    cities: [],
  },
  mutations: {
    setCitiesList: (state, { list }) => {
      state.cities = list;
    },
  },
  actions: {
    async LOAD_CITIES({ commit }) {
      const response = await warehouseCities();
      commit("setCitiesList", { list: response.data });
      return response;
    },
  },
};
