export default {
  namespaced: true,
  state: {
    stepAccount: false,
    stepActivation: false,
    stepDownload: false,
  },
  mutations: {
    setStepAccount: (state, stepAccount) => {
      state.stepAccount = stepAccount;
    },
    setStepActivation: (state, stepActivation) => {
      state.stepActivation = stepActivation;
    },
    setStepDownload: (state, stepDownload) => {
      state.stepDownload = stepDownload;
    },
  },
  actions: {
    SET_ACCOUNT({ commit }, value) {
      commit("setStepAccount", value);
    },
    SET_ACTIVATION({ commit }, value) {
      commit("setStepActivation", value);
    },
    SET_DOWNLOAD({ commit }, value) {
      commit("setStepDownload", value);
    },
    RESET_ALL({ commit }) {
      commit("setStepAccount", false);
      commit("setStepActivation", false);
      commit("setStepDownload", false);
    },
  },
};
