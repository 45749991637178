import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
  site: "ES",
  showSwitcher: false,
};

const mutations = {
  setSite(state, site) {
    state.site = site;
  },
  setSwitcher(state, switcher) {
    state.showSwitcher = switcher;
  },
};

const actions = {
  SET_SITE({ commit }, value) {
    commit("setSite", value);
  },
  SET_SHOW_SWITCHER({ commit }, value) {
    commit("setSwitcher", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
