import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
  spaceViewLocale: null,
};

const mutations = {
  setSpaceViewLocale(state, value) {
    state.spaceViewLocale = value;
  },
};

const actions = {
  SET_SPACE_VIEW_LOCALE({ commit }, value) {
    commit("setSpaceViewLocale", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
