const europeanObjects = {
  bed_160: {
    amount: 0,
    width: 200,
    height: 150,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "bed_160",
  },
  bed_135: {
    amount: 0,
    width: 200,
    height: 135,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "bed_135",
  },
  bed_80: {
    amount: 0,
    width: 200,
    height: 90,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "bed_80",
  },
  trundle_bed: {
    amount: 0,
    width: 200,
    height: 90,
    depth: 80,
    total: 0,
    room: "bedroom",
    id: "trundle_bed",
  },
  headboard: {
    amount: 0,
    width: 100,
    height: 300,
    depth: 5,
    total: 0,
    room: "bedroom",
    id: "headboard",
  },
  normal_desk: {
    amount: 0,
    width: 75,
    height: 100,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "normal_desk",
  },
  big_desk: {
    amount: 0,
    width: 80,
    height: 140,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "big_desk",
  },
  night_table: {
    amount: 0,
    width: 60,
    height: 50,
    depth: 40,
    total: 0,
    room: "bedroom",
    id: "night_table",
  },
  wardrobe_2: {
    amount: 0,
    width: 200,
    height: 100,
    depth: 60,
    total: 0,
    room: "bedroom",
    id: "wardrobe_2",
  },
  wardrobe_3: {
    amount: 0,
    width: 210,
    height: 150,
    depth: 60,
    total: 0,
    room: "bedroom",
    id: "wardrobe_3",
  },
  drawer: {
    amount: 0,
    width: 100,
    height: 60,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "drawer",
  },
  commode: {
    amount: 0,
    width: 100,
    height: 80,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "commode",
  },
  office_desk: {
    amount: 0,
    width: 75,
    height: 150,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "office_desk",
  },
  sinfonier: {
    amount: 0,
    width: 200,
    height: 80,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "sinfonier",
  },
  bathroom_hanging_drawer: {
    amount: 0,
    width: 50,
    height: 50,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "bathroom_hanging_drawer",
  },
  shoe_rack: {
    amount: 0,
    width: 80,
    height: 80,
    depth: 30,
    total: 0,
    room: "bedroom",
    id: "shoe_rack",
  },
  shelf: {
    amount: 0,
    width: 100,
    height: 80,
    depth: 40,
    total: 0,
    room: "bedroom",
    id: "shelf",
  },
  buffet_sideboard: {
    amount: 0,
    width: 100,
    height: 70,
    depth: 40,
    total: 0,
    room: "bedroom",
    id: "buffet_sideboard",
  },
  sofa: {
    amount: 0,
    width: 240,
    height: 100,
    depth: 80,
    total: 0,
    room: "livingroom",
    id: "sofa",
  },
  sofa_l: {
    amount: 0,
    width: 180,
    height: 120,
    depth: 160,
    total: 0,
    room: "livingroom",
    id: "sofa_l",
  },
  sleeper_sofa: {
    amount: 0,
    width: 200,
    height: 100,
    depth: 80,
    total: 0,
    room: "livingroom",
    id: "sleeper_sofa",
  },
  dining_table: {
    amount: 0,
    width: 80,
    height: 150,
    depth: 50,
    total: 0,
    room: "livingroom",
    id: "dining_table",
  },
  desmounted_dining_table: {
    amount: 0,
    width: 150,
    height: 100,
    depth: 20,
    total: 0,
    room: "livingroom",
    id: "desmounted_dining_table",
  },
  auxiliary_table: {
    amount: 0,
    width: 40,
    height: 100,
    depth: 40,
    total: 0,
    room: "livingroom",
    id: "auxiliary_table",
  },
  livingroom_modules: {
    amount: 0,
    width: 200,
    height: 100,
    depth: 50,
    total: 0,
    room: "livingroom",
    id: "livingroom_modules",
  },
  office_chair: {
    amount: 0,
    width: 80,
    height: 60,
    depth: 60,
    total: 0,
    room: "livingroom",
    id: "office_chair",
  },
  chairs: {
    amount: 0,
    width: 80,
    height: 60,
    depth: 40,
    total: 0,
    room: "livingroom",
    id: "chairs",
  },
  cupboard: {
    amount: 0,
    width: 100,
    height: 120,
    depth: 40,
    total: 0,
    room: "livingroom",
    id: "cupboard",
  },
  tv_furniture: {
    amount: 0,
    width: 70,
    height: 150,
    depth: 40,
    total: 0,
    room: "livingroom",
    id: "tv_furniture",
  },
  showcase: {
    amount: 0,
    width: 200,
    height: 100,
    depth: 50,
    total: 0,
    room: "livingroom",
    id: "showcase",
  },
  floor_lamp: {
    amount: 0,
    width: 100,
    height: 50,
    depth: 30,
    total: 0,
    room: "livingroom",
    id: "floor_lamp",
  },
  armchair: {
    amount: 0,
    width: 80,
    height: 70,
    depth: 70,
    total: 0,
    room: "livingroom",
    id: "armchair",
  },
  bookshelf: {
    amount: 0,
    width: 200,
    height: 100,
    depth: 50,
    total: 0,
    room: "livingroom",
    id: "bookshelf",
  },
  glass_table: {
    amount: 0,
    width: 100,
    height: 100,
    depth: 40,
    total: 0,
    room: "livingroom",
    id: "glass_table",
  },
  ceiling_lamp: {
    amount: 0,
    width: 30,
    height: 50,
    depth: 30,
    total: 0,
    room: "livingroom",
    id: "ceiling_lamp",
  },
  entrance_furniture: {
    amount: 0,
    width: 100,
    height: 120,
    depth: 40,
    total: 0,
    room: "livingroom",
    id: "entrance_furniture",
  },
  appliances: {
    amount: 0,
    width: 80,
    height: 60,
    depth: 60,
    total: 0,
    room: "kitchen",
    id: "appliances",
  },
  fridge: {
    amount: 0,
    width: 200,
    height: 70,
    depth: 60,
    total: 0,
    room: "kitchen",
    id: "fridge",
  },
  american_fridge: {
    amount: 0,
    width: 200,
    height: 120,
    depth: 80,
    total: 0,
    room: "kitchen",
    id: "american_fridge",
  },
  stacking_chairs: {
    amount: 0,
    width: 80,
    height: 50,
    depth: 5,
    total: 0,
    room: "kitchen",
    id: "stacking_chairs",
  },
  no_stacking_chairs: {
    amount: 0,
    width: 80,
    height: 50,
    depth: 50,
    total: 0,
    room: "kitchen",
    id: "no_stacking_chairs",
  },
  microwave: {
    amount: 0,
    width: 30,
    height: 40,
    depth: 40,
    total: 0,
    room: "kitchen",
    id: "microwave",
  },
  kitchen_table: {
    amount: 0,
    width: 75,
    height: 110,
    depth: 50,
    total: 0,
    room: "kitchen",
    id: "kitchen_table",
  },
  terrace_table: {
    amount: 0,
    width: 75,
    height: 100,
    depth: 50,
    total: 0,
    room: "kitchen",
    id: "terrace_table",
  },
  terrace_armchair: {
    amount: 0,
    width: 70,
    height: 70,
    depth: 70,
    total: 0,
    room: "kitchen",
    id: "terrace_armchair",
  },
  kitchen_drawer: {
    amount: 0,
    width: 100,
    height: 40,
    depth: 40,
    total: 0,
    room: "kitchen",
    id: "kitchen_drawer",
  },
  entrance_glass_table: {
    amount: 0,
    width: 100,
    height: 130,
    depth: 40,
    total: 0,
    room: "kitchen",
    id: "entrance_glass_table",
  },
  standing_coat_rack: {
    amount: 0,
    width: 100,
    height: 20,
    depth: 20,
    total: 0,
    room: "kitchen",
    id: "standing_coat_rack",
  },
  pantry_cabinet: {
    amount: 0,
    width: 180,
    height: 100,
    depth: 40,
    total: 0,
    room: "kitchen",
    id: "pantry_cabinet",
  },
  l_box: {
    amount: 0,
    width: 70,
    height: 50,
    depth: 50,
    total: 0,
    room: "other",
    id: "l_box",
  },
  m_box: {
    amount: 0,
    width: 50,
    height: 35,
    depth: 35,
    space_multiplier: 1.9,
    total: 0,
    room: "other",
    id: "m_box",
  },
  s_box: {
    amount: 0,
    width: 35,
    height: 30,
    depth: 20,
    space_multiplier: 2.5,
    total: 0,
    room: "other",
    id: "s_box",
  },
  w_box: {
    amount: 0,
    width: 100,
    height: 60,
    depth: 50,
    space_multiplier: 1.8,
    total: 0,
    room: "other",
    id: "w_box",
  },
  suitcase: {
    amount: 0,
    width: 60,
    height: 70,
    depth: 30,
    total: 0,
    room: "other",
    id: "suitcase",
  },
  mirror: {
    amount: 0,
    width: 100,
    height: 70,
    depth: 5,
    total: 0,
    room: "other",
    id: "mirror",
  },
  tv: {
    amount: 0,
    width: 70,
    height: 110,
    depth: 20,
    total: 0,
    room: "other",
    id: "tv",
  },
  bike: {
    amount: 0,
    width: 50,
    height: 120,
    depth: 50,
    total: 0,
    room: "other",
    id: "bike",
  },
  shopping_cart: {
    amount: 0,
    width: 40,
    height: 40,
    depth: 40,
    total: 0,
    room: "other",
    id: "shopping_cart",
  },
  stool: {
    amount: 0,
    width: 100,
    height: 50,
    depth: 60,
    total: 0,
    room: "other",
    id: "stool",
  },
  fan: {
    amount: 0,
    width: 100,
    height: 50,
    depth: 30,
    total: 0,
    room: "other",
    id: "fan",
  },
  chest: {
    amount: 0,
    width: 50,
    height: 110,
    depth: 50,
    total: 0,
    room: "other",
    id: "chest",
  },
  bureau: {
    amount: 0,
    width: 100,
    height: 100,
    depth: 40,
    total: 0,
    room: "other",
    id: "bureau",
  },
};

const mexicanObjects = {
  bed_individual: {
    amount: 0,
    height: 190,
    width: 100,
    depth: 60,
    total: 0,
    room: "bedroom",
    id: "bed_individual",
  },
  bed_wedding: {
    amount: 0,
    height: 190,
    width: 135,
    depth: 60,
    total: 0,
    room: "bedroom",
    id: "bed_wedding",
  },
  bed_queen: {
    amount: 0,
    height: 190,
    width: 150,
    depth: 60,
    total: 0,
    room: "bedroom",
    id: "bed_queen",
  },
  bed_king: {
    amount: 0,
    height: 190,
    width: 200,
    depth: 60,
    total: 0,
    room: "bedroom",
    id: "bed_king",
  },
  bed_presidential: {
    amount: 0,
    height: 200,
    width: 200,
    depth: 60,
    total: 0,
    room: "bedroom",
    id: "bed_presidential",
  },
  bed_california: {
    amount: 0,
    height: 185,
    width: 213,
    depth: 60,
    total: 0,
    room: "bedroom",
    id: "bed_california",
  },
  bed_c_individual: {
    amount: 0,
    height: 100,
    width: 100,
    depth: 10,
    total: 0,
    room: "bedroom",
    id: "bed_c_individual",
  },
  bed_c_wedding: {
    amount: 0,
    height: 100,
    width: 135,
    depth: 10,
    total: 0,
    room: "bedroom",
    id: "bed_c_wedding",
  },
  bed_c_queen: {
    amount: 0,
    height: 100,
    width: 150,
    depth: 10,
    total: 0,
    room: "bedroom",
    id: "bed_c_queen",
  },
  bed_c_king: {
    amount: 0,
    height: 100,
    width: 200,
    depth: 10,
    total: 0,
    room: "bedroom",
    id: "bed_c_king",
  },
  bed_c_presidential: {
    amount: 0,
    height: 100,
    width: 200,
    depth: 10,
    total: 0,
    room: "bedroom",
    id: "bed_c_presidential",
  },
  bed_c_california: {
    amount: 0,
    height: 100,
    width: 213,
    depth: 10,
    total: 0,
    room: "bedroom",
    id: "bed_c_california",
  },
  desktop: {
    amount: 0,
    height: 75,
    width: 120,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "desktop",
  },
  night_table: {
    amount: 0,
    height: 45,
    width: 50,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "night_table",
  },
  wardrobe_2: {
    amount: 0,
    height: 200,
    width: 100,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "wardrobe_2",
  },
  wardrobe_3: {
    amount: 0,
    height: 200,
    width: 150,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "wardrobe_3",
  },
  drawer: {
    amount: 0,
    height: 60,
    width: 70,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "drawer",
  },
  sinfonier: {
    amount: 0,
    height: 100,
    width: 70,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "sinfonier",
  },
  shoe_rack: {
    amount: 0,
    height: 70,
    width: 80,
    depth: 30,
    total: 0,
    room: "bedroom",
    id: "shoe_rack",
  },
  chest_of_drawers: {
    amount: 0,
    height: 100,
    width: 80,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "chest_of_drawers",
  },
  dressing_table: {
    amount: 0,
    height: 120,
    width: 120,
    depth: 50,
    total: 0,
    room: "bedroom",
    id: "dressing_table",
  },
  rack: {
    amount: 0,
    height: 200,
    width: 100,
    depth: 40,
    total: 0,
    room: "bedroom",
    id: "rack",
  },
  sofa_2: {
    amount: 0,
    height: 200,
    width: 100,
    depth: 80,
    total: 0,
    room: "livingroom",
    id: "sofa_2",
  },
  sofa_3: {
    amount: 0,
    height: 240,
    width: 100,
    depth: 80,
    total: 0,
    room: "livingroom",
    id: "sofa_3",
  },
  sofa_4: {
    amount: 0,
    height: 280,
    width: 100,
    depth: 80,
    total: 0,
    room: "livingroom",
    id: "sofa_4",
  },
  sofa_l: {
    amount: 0,
    height: 180,
    width: 120,
    depth: 160,
    total: 0,
    room: "livingroom",
    id: "sofa_l",
  },
  dining_table: {
    amount: 0,
    height: 75,
    width: 150,
    depth: 50,
    total: 0,
    room: "livingroom",
    id: "dining_table",
  },
  coffee_table: {
    amount: 0,
    height: 50,
    width: 100,
    depth: 40,
    total: 0,
    room: "livingroom",
    id: "coffee_table",
  },
  auxiliary_table: {
    amount: 0,
    height: 50,
    width: 60,
    depth: 50,
    total: 0,
    room: "livingroom",
    id: "auxiliary_table",
  },
  tv_furniture: {
    amount: 0,
    height: 100,
    width: 100,
    depth: 40,
    total: 0,
    room: "livingroom",
    id: "tv_furniture",
  },
  cupboard: {
    amount: 0,
    height: 100,
    width: 120,
    depth: 50,
    total: 0,
    room: "livingroom",
    id: "cupboard",
  },
  buffet_sideboard: {
    amount: 0,
    height: 120,
    width: 150,
    depth: 50,
    total: 0,
    room: "livingroom",
    id: "buffet_sideboard",
  },
  armchair: {
    amount: 0,
    height: 80,
    width: 70,
    depth: 70,
    total: 0,
    room: "livingroom",
    id: "armchair",
  },
  floor_lamp: {
    amount: 0,
    height: 200,
    width: 30,
    depth: 30,
    total: 0,
    room: "livingroom",
    id: "floor_lamp",
  },
  ceiling_lamp: {
    amount: 0,
    height: 50,
    width: 50,
    depth: 50,
    total: 0,
    room: "livingroom",
    id: "ceiling_lamp",
  },
  bureau: {
    amount: 0,
    height: 75,
    width: 120,
    depth: 50,
    total: 0,
    room: "livingroom",
    id: "bureau",
  },
  dinning_chair: {
    amount: 0,
    height: 80,
    width: 60,
    depth: 40,
    total: 0,
    room: "livingroom",
    id: "dinning_chair",
  },
  fridge: {
    amount: 0,
    height: 200,
    width: 70,
    depth: 60,
    total: 0,
    room: "kitchen",
    id: "fridge",
  },
  washing_machine: {
    amount: 0,
    height: 80,
    width: 60,
    depth: 60,
    total: 0,
    room: "kitchen",
    id: "washing_machine",
  },
  dryer: {
    amount: 0,
    height: 80,
    width: 60,
    depth: 60,
    total: 0,
    room: "kitchen",
    id: "dryer",
  },
  dishwasher: {
    amount: 0,
    height: 80,
    width: 60,
    depth: 60,
    total: 0,
    room: "kitchen",
    id: "dishwasher",
  },
  microwave: {
    amount: 0,
    height: 30,
    width: 40,
    depth: 40,
    total: 0,
    room: "kitchen",
    id: "microwave",
  },
  kitchen_table: {
    amount: 0,
    height: 75,
    width: 110,
    depth: 50,
    total: 0,
    room: "kitchen",
    id: "kitchen_table",
  },
  pantry_cabinet: {
    amount: 0,
    height: 180,
    width: 100,
    depth: 40,
    total: 0,
    room: "kitchen",
    id: "pantry_cabinet",
  },
  chairs: {
    amount: 0,
    height: 80,
    width: 60,
    depth: 40,
    total: 0,
    room: "kitchen",
    id: "chairs",
  },
  terrace_armchair: {
    amount: 0,
    height: 70,
    width: 70,
    depth: 70,
    total: 0,
    room: "kitchen",
    id: "terrace_armchair",
  },
  terrace_coffee_table: {
    amount: 0,
    height: 40,
    width: 100,
    depth: 50,
    total: 0,
    room: "kitchen",
    id: "terrace_coffee_table",
  },
  outdoor_dining_table: {
    amount: 0,
    height: 75,
    width: 150,
    depth: 50,
    total: 0,
    room: "kitchen",
    id: "outdoor_dining_table",
  },
  outdoor_auxiliary_table: {
    amount: 0,
    height: 40,
    width: 100,
    depth: 40,
    total: 0,
    room: "kitchen",
    id: "outdoor_auxiliary_table",
  },
  l_box: {
    amount: 0,
    height: 70,
    width: 50,
    depth: 50,
    total: 0,
    room: "other",
    id: "l_box",
  },
  m_box: {
    amount: 0,
    height: 50,
    width: 35,
    depth: 35,
    total: 0,
    space_multiplier: 1.9,
    room: "other",
    id: "m_box",
  },
  s_box: {
    amount: 0,
    height: 35,
    width: 30,
    depth: 20,
    space_multiplier: 2.5,
    total: 0,
    room: "other",
    id: "s_box",
  },
  suitcase: {
    amount: 0,
    height: 60,
    width: 70,
    depth: 30,
    total: 0,
    room: "other",
    id: "suitcase",
  },
  mirror: {
    amount: 0,
    height: 100,
    width: 100,
    depth: 5,
    total: 0,
    room: "other",
    id: "mirror",
  },
  tv: {
    amount: 0,
    height: 70,
    width: 110,
    depth: 20,
    total: 0,
    room: "other",
    id: "tv",
  },
  bike: {
    amount: 0,
    height: 200,
    width: 120,
    depth: 50,
    total: 0,
    room: "other",
    id: "bike",
  },
  shopping_cart: {
    amount: 0,
    height: 60,
    width: 30,
    depth: 20,
    total: 0,
    room: "other",
    id: "shopping_cart",
  },
  fan: {
    amount: 0,
    height: 40,
    width: 100,
    depth: 50,
    total: 0,
    room: "other",
    id: "fan",
  },
  chest: {
    amount: 0,
    height: 100,
    width: 30,
    depth: 30,
    total: 0,
    room: "other",
    id: "chest",
  },
  radiator: {
    amount: 0,
    height: 50,
    width: 50,
    depth: 30,
    total: 0,
    room: "other",
    id: "radiator",
  },
  stairs: {
    amount: 0,
    height: 100,
    width: 40,
    depth: 10,
    total: 0,
    room: "other",
    id: "stairs",
  },
  clothesline: {
    amount: 0,
    height: 100,
    width: 50,
    depth: 10,
    total: 0,
    room: "other",
    id: "clothesline",
  },
  bench: {
    amount: 0,
    height: 40,
    width: 100,
    depth: 40,
    total: 0,
    room: "other",
    id: "bench",
  },
};

export { europeanObjects, mexicanObjects };
